import React, { useState } from 'react';
import { withPrefix } from 'gatsby';
import { Button, Col, Row, Table, Tooltip } from 'reactstrap';

import Wrapper from '../components/wrapper';

export default props => {
  const page = props['*'];
  const [ulTooltipOpen, setUlTooltipOpen] = useState(false);
  const [csaTooltipOpen, setCsaTooltipOpen] = useState(false);
  const [tuvTooltipOpen, setTuvTooltipOpen] = useState(false);
  const [bsiTooltipOpen, setBsiTooltipOpen] = useState(false);
  const [nemkoTooltipOpen, setNemkoTooltipOpen] = useState(false);
  const [vdeTooltipOpen, setVdeTooltipOpen] = useState(false);

  return (
    <Wrapper page={page}>
      <h1 className='d-block d-lg-none page'>Safety Certifications</h1>
      <Row>
        <Col md='5' lg='4' xl='3'>
          <h2>TEX-E / TEX-ELZ</h2>
        </Col>
        <Col md='7' lg='8' xl='9'>
          <a
            className='text-center'
            target='_blank'
            rel='noopener noreferrer'
            href={withPrefix('/pdf/fis-b1.pdf')}
          >
            <Button>UL 1446 Insulation System FIS-B1</Button>
          </a>
        </Col>
      </Row>
      <Table hover responsive className="mt-3">
        <thead className='thead-light'>
          <tr>
            <th>Organization</th>
            <th>
              Requirements Standard No.
              <sup>
                <a href='#notes1'>(note 1)</a>
              </sup>
            </th>
            <th>
              Cert. No.
              <sup>
                <a href='#notes2'>(note 2)</a>
              </sup>
            </th>
            <th>Rating</th>
            <th>Certificated Product</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.ul.com/'
                id='ulTooltip'
              >
                UL
                <Tooltip
                  placement='bottom'
                  isOpen={ulTooltipOpen}
                  target='ulTooltip'
                  toggle={() => setUlTooltipOpen(!ulTooltipOpen)}
                >
                  Underwriters Laboratories Inc.
                </Tooltip>
              </a>
            </th>
            <td>
              UL 2353
              <br />
              UL 1950/IEC 60950-1, Annex U<br />
              (UL 1411，UL 60950-1)
              <br />
            </td>
            <td>E206440,OBJT2</td>
            <td>
              1000 Vrms
              <br />
              Class B
              <sup>
                <a href='#notes3'>(note 3)</a>
              </sup>
            </td>
            <td>
              TEX-E
              <br />
              TEX-ELZ
            </td>
          </tr>
          <tr>
            <th>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='http://www.csa.ca/'
                id='csaTooltip'
              >
                CSA
                <br />
                (NRTL/C)
              </a>
              <Tooltip
                placement='bottom'
                isOpen={csaTooltipOpen}
                target='csaTooltip'
                toggle={() => setCsaTooltipOpen(!csaTooltipOpen)}
              >
                Canadian Standards Association
              </Tooltip>
            </th>
            <td>
              CAN/CSA 60065-03 incl. AM1+AM2
              <br />
              ANSI/UL Std No. 60065-2013
              <br />
              CAN/CSA-C22.2 No. 60950-1-07+AM1, AM2
              <br />
              ANSI/UL 60950-1-2014
            </td>
            <td>
              Master Contract
              <br />
              185274
              <br />
              (LR104155)
            </td>
            <td>
              1000 Vrms
              <br />
              Class B
            </td>
            <td>
              TEX-E
              <br />
              TEX-ELZ
            </td>
          </tr>
          <tr>
            <th>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='http://www.tuv.com/'
                id='tuvTooltip'
              >
                TUV
                <br />
                Rheinland
              </a>
              <Tooltip
                placement='bottom'
                isOpen={tuvTooltipOpen}
                target='tuvTooltip'
                toggle={() => setTuvTooltipOpen(!tuvTooltipOpen)}
              >
                TUV Rheinland Berlin Brandenburg
              </Tooltip>
            </th>
            <td>
              EN 60950-1:2006+A11+A1+A12+A2
              <br />
              IEC 60950-1:2005+A1+A2
              <br />
              DIN EN 60950-1:2011+A12
              <br />
              IEC 60065:2001+A1+A2
              <br />
              EN 60065:2002+A1+A11+A2+A12
            </td>
            <td>T9251520</td>
            <td>
              1000 Vrms
              <br />
              Class B<br />
              500kHz
              <br />
            </td>
            <td>
              TEX-E
              <br />
              TEX-ELZ
            </td>
          </tr>
          <tr>
            <th>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='http://www.bsigroup.com/'
                id='bsiTooltip'
              >
                BSI
              </a>
              <Tooltip
                placement='bottom'
                isOpen={bsiTooltipOpen}
                target='bsiTooltip'
                toggle={() => setBsiTooltipOpen(!bsiTooltipOpen)}
              >
                Bristish Standards
              </Tooltip>
            </th>
            <td>
              BS EN 60065: 2002
              <br />
              IEC 60065: 2001
              <br />
              BS EN 60950-1:2006
              <br />
              IEC 60950-1:2005
              <br />
            </td>
            <td>KM 36738</td>
            <td>
              1000 Vrms
              <br />
              Class E
            </td>
            <td>TEX-E</td>
          </tr>
          <tr>
            <th>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='http://www.nemko.com/'
                id='nemkoTooltip'
              >
                NEMKO
              </a>
              <Tooltip
                placement='bottom'
                isOpen={nemkoTooltipOpen}
                target='nemkoTooltip'
                toggle={() => setNemkoTooltipOpen(!nemkoTooltipOpen)}
              >
                Norges Elektriske Materiellkontroll
              </Tooltip>
            </th>
            <td>
              EN 60065:2002;A1;A11;A2;A12
              <br />
              EN 60950-1:2006;A11;A1;A12;A2
              <br />
            </td>
            <td>P10212368/A5</td>
            <td>
              1000 Vrms
              <br />
              Class E
            </td>
            <td>
              TEX-E
              <br />
              TEX-ELZ
            </td>
          </tr>
          <tr>
            <th>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='http://www.vde.de/'
                id='vdeTooltip'
              >
                VDE
              </a>
              <Tooltip
                placement='bottom'
                isOpen={vdeTooltipOpen}
                target='vdeTooltip'
                toggle={() => setVdeTooltipOpen(!vdeTooltipOpen)}
              >
                VDE Association for Electrical, Electronic & Information
                Technologies
              </Tooltip>
            </th>
            <td>
              DIN EN 60950-1 (0805-1):2014-08;
              EN60950-1:2006+A11+A1+A12+AC+A2:2013
              <br />
              IEC60950-1:2005/AM1:2009/AM2:2013
              <br />
              DIN EN 60065 (VDE 0860):2015-11
              <br />
              EN 60065:2014
              <br />
              IEC 60065:2014(eight edition)
              <br />
              IEC62368-1(ed.2)
              <br />
              DIN EN 60601-1 (VDE 0750-1):2013-12
              <br />
              EN 60601-1/A1:2013-10
              <br />
              IEC 60601-1 (ed.3);am1
              <br />
              DIN EN 61558-1/A1 (VDE0570-1/A1):2009-11
              <br />
              EN 61558-1/A1:2009
              <br />
              IEC61558-1 (Second Edition) +A1:2009
              <br />
              DIN EN 61558-2-16 (VDE0570-2-16):2014-06
              <br />
              EN 61558-2-16:2009+A1:2013
              <br />
              IEC 61558-2-16:2009+A1:2013
            </td>
            <td>
              6735
              <br />
              &nbsp;(TEX-E)
              <br />
              <br />
              40032438 (TEX-ELZ)
              <br />
            </td>
            <td>
              500kHz
              <br />
              Class B<br />
              <br />
              1000 Vrms
              <br />
              &nbsp;(IEC 60950, IEC 60065, IEC62368)
              <br />
              <br />
              1000 Vrms
              <br />
              (IEC 60601, except for 600Vrms for Two MOPP) <br />
              <br />
              600Vrms(IEC61558)
            </td>
            <td>
              TEX-E
              <br />
              TEX-ELZ
            </td>
          </tr>
        </tbody>
      </Table>
      <div id='notes1' className='row'>
        <div className='col-sm-4 col-md-3 col-lg-2'>
          <em>(note 1)</em>
        </div>
        <div className='col-sm-8 col-md-9 col-lg-10'>
          <p>
            TEX-E series is reinforced insulation wire which comply with clause
            2.10.5.12, Annex U of IEC 60950-1 2nd edition and test voltage
            3kVrms for 1min by clause 8.17, Annex H of IEC60065 7th edition. And
            TEX-E series also comply with 6kVrms for 1min in twist pair sample
            following to these standards.
          </p>
        </div>
      </div>
      <div id='notes2' className='row'>
        <div className='col-sm-4 col-md-3 col-lg-2'>
          <em>(note 2)</em>
        </div>
        <div className='col-sm-8 col-md-9 col-lg-10'>
          <p>
            Numbering is subject to change following to the revision of the
            standard.
          </p>
        </div>
      </div>
      <div id='notes3' className='row'>
        <div className='col-sm-4 col-md-3 col-lg-2'>
          <em>(note 3)</em>
        </div>
        <div className='col-sm-8 col-md-9 col-lg-10'>
          <p>
            UL requests wires to be evaluated following to the proper insulation
            system (UL1446/IEC61857) regardless the temperature resistivity of
            wires or parts when it is used above class A(105℃).
          </p>
        </div>
      </div>
    </Wrapper>
  );
};
